import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'
import { get, post, put, patch, destroy } from '@rails/request.js'

export default class extends Controller {
  static targets = ['notifyBtn', 'closeBtn', 'wrapArea', 'maskArea', 'member', 'unreadCount', 'allNotificationsSection', 'unreadNotificationsSection', 'allNotificationsBtn', 'unreadNotificationsBtn']
  static values = {
    notified: Boolean,
    readUrl: String,
    unreadCount: Number
  }
  connect() {
    document.body.addEventListener('click', () => {
      this.hiddenDisplay()
    })
    document.getElementById('mobile-notify').addEventListener('click', (e) => {
      this.notifyBtnClicked(e)
    })

    const readAllBtns = $('.notify-wrap .readall')
    for(let i = 0; i < readAllBtns.length; i++) {
      const btn = readAllBtns[i]
      btn.addEventListener('click', (e) => {
        this.readAllBtnClicked(e)
      })
    }
    const notificationLinks = $('a.notification-link, .notification-link > a')
    for(let i = 0; i < notificationLinks.length; i++) {
      const link = notificationLinks[i]
      link.addEventListener('click', (e) => {
        e.preventDefault();

        this.notificationItemClicked(link)
      })
    }
  }

  unreadCountValueChanged() {
    this.unreadCountTargets.forEach(target => {
      if (this.unreadCountValue === 0) {
        target.classList.add('hidden')
      } else {
        target.classList.remove('hidden')
      }

      const unreadCountText = (this.unreadCountValue > 9) ? '9+' : this.unreadCountValue
      target.innerText = unreadCountText
    })

    const mobileUnreadCount = document.getElementById('mobile-notifications-unread-count')
    if (mobileUnreadCount) {
      if (this.unreadCountValue === 0) {
        mobileUnreadCount.classList.add('hidden')
      } else {
        mobileUnreadCount.classList.remove('hidden')
      }
      const mobileUnreadCountText = (this.unreadCountValue > 9) ? '9+' : this.unreadCountValue
      mobileUnreadCount.innerText = mobileUnreadCountText
    }
  }

  async sendReadAllRequest(e) {
    const unreadIds = this.memberTargets.map(function(ele){
      if(ele.dataset.readat == 0){
        return parseInt(ele.dataset.id)
      }
    }).filter(id => id !== undefined && !isNaN(id))

    if(unreadIds.length > 0){
      const resp = await patch(this.readUrlValue, {body: JSON.stringify({ids: unreadIds})})
      if(resp.ok){
        this.notifiedValue = true
        this.memberTargets.forEach(function(ele){
          ele.dataset.readat = Date.now()
        })
        this.unreadCountValue = 0

        // remove notifications & hr element & readall btn
        $('.readall').remove()
        $('.unread-hint-point').remove()
        $('#unread_notifications-settings .notification-item').remove();    
        $('#unread_notifications-settings hr.border-grey-3').remove();    

        // add notify empty msg back
        $('#unread_notifications-settings .notification-item').remove();    

        document.querySelector('.unread-notification-empty-msg').classList.remove('hidden')
      }
    }
  }

  async sendReadRequest(link) {
    const ele = link.closest('.notification-item')

    if(ele.dataset.readat == 0){
      const resp = await patch(this.readUrlValue, {body: JSON.stringify({ids: [ ele.dataset.id ]})})

      if(resp.ok){
        ele.dataset.readat = Date.now()
        window.location = link.href
      }
    } else {
      if(link) {
        window.location = link.href
      }
    }
  }

  notifyBtnClicked(e) {
    e.preventDefault()
    e.stopPropagation()
    this.hiddenModal()
    this.toggleDisplay()
  }

  readAllBtnClicked(e) {
    this.sendReadAllRequest()
  }

  notificationItemClicked(link) {
    this.sendReadRequest(link)
  }

  wrapClicked(e) {
    e.stopPropagation()
  }

  hiddenDisplay() {
    this.element.classList.add('close')
    this.maskAreaTarget.classList.add('hidden')
  }

  toggleDisplay() {
    this.element.classList.toggle('close')
    this.maskAreaTarget.classList.toggle('hidden')
  }

  hiddenModal() {
    const modal = document.querySelector('input.dropdown')
    if (modal) {
      modal.checked = false
    }
  }
  switchToUnreadNotifications(e){
    e.preventDefault()
    e.stopPropagation()
    this.allNotificationsBtnTarget.classList.remove('active')
    this.unreadNotificationsBtnTarget.classList.add('active')    
    this.unreadNotificationsSectionTarget.classList.remove('hidden')
    this.allNotificationsSectionTarget.classList.add('hidden')
  }
  switchToAllNotifications(e){
    e.preventDefault()
    e.stopPropagation()
    this.allNotificationsBtnTarget.classList.add('active')
    this.unreadNotificationsBtnTarget.classList.remove('active')
    this.unreadNotificationsSectionTarget.classList.add('hidden')
    this.allNotificationsSectionTarget.classList.remove('hidden')
  }
}
